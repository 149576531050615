










import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';

@Component({})
export default class QuestionSlider extends Vue {
  private sliderValue!: number;

  @Prop({ default: '#FF5A1C' })
  private startColor!: string;

  @Prop({ default: '#00B38F' })
  private endColor!: string;

  @Emit()
  private sliderValueChanged(value: number) {
    // empty
  }

  private changed(event: Event) {
    if (event && event.target) {
      const slider: HTMLInputElement = event.target as HTMLInputElement;
      this.sliderValue = slider.valueAsNumber;
      console.log('slider', slider.valueAsNumber);
      this.sliderValueChanged(slider.valueAsNumber);
    }
  }
}
