











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BackLink extends Vue {
  @Prop({ default: false })
  private readonly genericBackAction!: boolean;

  @Prop({ required: true })
  private readonly text!: string;

  @Prop({ default: '/' })
  private readonly href!: string;

  @Prop({ required: true })
  private readonly color!: string;

  private get classes(): string {
    return `hover:underline text-${this.color} inline-flex items-center`;
  }
}
