











import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';

export interface ChoiceBarOption {
  id: number;
  text: string;
}

@Component
export default class SurveyEditorSingleChoiceBar extends Vue {

  @VModel({ default: 0 })
  private selectedOptionId!: number;

  @Prop({ required: true })
  private readonly options!: ChoiceBarOption[];

  // private selectionStates: { optionData: ChoiceBarOption; selected: boolean }[] = [];

  @Emit()
  private selectionChanged(id: number) {
    // empty
  }

  private get selectionStates(): { optionData: ChoiceBarOption; selected: boolean }[] {
    return this.options.map((o) => ({
      selected: o.id === this.selectedOptionId,
      optionData: o,
    }));
  }

  public mounted(): void {
    /* this.selectionStates = this.options.map((o) => ({
      selected: false,
      optionData: o,
    }));

    this.selectOption(0); */
  }

  private getOptionCssClasses(id: number): string {
    let classes = 'text-center';

    if (id > 0) {
      classes += ' border-l-0 md:border-l-2 border-t-2 md:border-t-0';
    }

    return classes;
  }

  private selectOption(id: number) {
    // eslint-disable-next-line no-param-reassign
    /* this.selectionStates.forEach((s) => s.selected = false);
    const selectedOption = this.selectionStates.find((s) => s.optionData.id === id);

    if (selectedOption) {
      selectedOption.selected = true;
      this.selectionChanged(selectedOption.optionData.id);
    } */
    this.selectedOptionId = id;
    this.selectionChanged(id);
  }
}
