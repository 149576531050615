























import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { ValidationError } from '@/views/surveys/SurveyEditor.vue';
import { SurveyQuestionAnswerRestDTO } from '@/apis/surveyapi';
import SurveyEditorErrorCodes from '@/assets/SurveyEditorErrorCodes';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import IconInCircle from '@/components/partials/IconInCircle.vue';

export interface QuestionInput {
  value: string;
  errors: SurveyEditorErrorCodes[];
}

@Component({
  components: { TextInputArea, IconInCircle },
})
export default class SurveyEditorAnswer extends Vue {
  private SurveyEditorErrorCodes = SurveyEditorErrorCodes;

  @VModel()
  private input!: SurveyQuestionAnswerRestDTO;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private readonly circle!: boolean;

  @Prop({ default: () => ([]) })
  private errors!: ValidationError[];

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }

  private get errorsToDisplay() {
    return this.errors
      .filter((e) => e.location === 'SUB_QUESTION' && e.subPosition === this.input.position)
      .map((e) => e.code);
  }
}
