























import {
  Component, VModel, Vue,
} from 'vue-property-decorator';
import { directive as onClickAway } from 'vue-clickaway';
import { Chrome } from 'vue-color';
import { SliderColorDataDto } from '@/apis/surveyapi';

export interface ColorInputData {
  startColor: Record<string, unknown>;
  endColor: Record<string, unknown>;
}

@Component({
  components: {
    Chrome,
  },
  directives: {
    onClickAway,
  },
})
export default class SurveyEditorSliderInput extends Vue {

  @VModel()
  private data!: SliderColorDataDto;

  private swatches = [
    '#FF5A1C',
    '#00B38F',
  ];

  private showStartColorPicker = false;
  private showEndColorPicker = false;

  private get startColorCircleCss() {
    return `background-color: ${this.data.slider_color_left ? this.data.slider_color_left.hex : ''}`;
  }

  private get endColorCircleCss() {
    return `background-color: ${this.data.slider_color_right ? this.data.slider_color_right.hex : ''}`;
  }

  private get sliderBarCss() {
    return `background: linear-gradient(90deg, ${this.data.slider_color_left ? this.data.slider_color_left.hex : ''}, ${this.data.slider_color_right ? this.data.slider_color_right.hex : ''})`;
  }

  private toggleStartColorPicker() {
    this.showStartColorPicker = !this.showStartColorPicker;
    this.showEndColorPicker = false;
  }

  private toggleEndColorPicker() {
    this.showEndColorPicker = !this.showEndColorPicker;
    this.showStartColorPicker = false;
  }

  private closeStartColorPicker(event: PointerEvent) {
    if (event.target && (event.target as HTMLElement).id !== 'startColorCircle') {
      this.showStartColorPicker = false;
    }
  }

  private closeEndColorPicker(event: PointerEvent) {
    if (event.target && (event.target as HTMLElement).id !== 'endColorCircle') {
      this.showEndColorPicker = false;
    }
  }
}
