


















































































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import {
  SurveyQuestionAnswerRestDTO,
  SurveyQuestionRestDTO,
  SurveyQuestionRestDTOChoiceTypeEnum,
} from '@/apis/surveyapi';
import Header from '@/assets/headers';
import SurveyEditorErrorCodes from '@/assets/SurveyEditorErrorCodes';
import DeleteDialog from '@/components/DeleteDialog.vue';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import SurveyCheckBoxTable from '@/components/surveys/SurveyCheckBoxTable.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';
import { SortableSubQuestion } from '@/components/surveys/editor/ScalaQuestion.vue';
import SurveyEditorAnswer from '@/components/surveys/editor/SurveyEditorAnswer.vue';
import SurveyEditorSingleChoiceBar, { ChoiceBarOption } from '@/components/surveys/editor/SurveyEditorSingleChoiceBar.vue';
import { ValidationError } from '@/views/surveys/SurveyEditor.vue';

export interface ChoiceQuestionData {
  selectedScaleTypeId: number;
  question: string;
  isAnswerOptional: boolean;
  allowFreeTextAnswer: boolean;
  freeTextQuestion: string;
  answers: SortableSubQuestion[];
  errors: SurveyEditorErrorCodes[];
}

@Component({
  components: {
    TextInputArea,
    DeleteDialog,
    SurveyEditorAnswer,
    TextInput,
    SurveyEditorSingleChoiceBar,
    SurveyCheckBoxTable,
    IconInCircle,
    TextHeader,
  },
})
export default class SurveyEditorChoiceQuestion extends Vue {
  private Header = Header;
  private SurveyEditorErrorCodes = SurveyEditorErrorCodes;

  @VModel()
  private data!: SurveyQuestionRestDTO;

  @Prop({ required: true })
  private heading!: string;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: () => ([]) })
  private errors!: ValidationError[];

  private selectedOptionId: number = 0;

  private hasFreetextAnswer: boolean = false;
  private freeTextQuestion: string = '';

  private showDeleteDialog = false;
  private indexOfSectionToDelete: number | undefined = undefined;
  private idCounter: number = 3;
  private selectedChoiceTypeId = 0;

  private options: ChoiceBarOption[] = [
    {
      id: 0,
      text: 'Einzelauswahl',
    },
    {
      id: 1,
      text: 'Mehrfachauswahl',
    },
    {
      id: 2,
      text: 'Kombinationsfeld',
    },
  ];

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }

  private get errorsToDisplay() {
    return this.errors
      .filter((e) => e.location === 'QUESTION' && e.position === this.data.position)
      .map((e) => e.code);
  }

  public mounted() {

    if (this.data.choice_answers && this.data.choice_answers.length > 0) {
      // eslint-disable-next-line prefer-spread
      this.idCounter = Math.max.apply(Math, this.data.choice_answers.map((s) => s.id as number)) + 1;
    }

    if (!this.data.choice_freetext_hint) {
      this.data.choice_freetext_hint = 'Sonstiges';
    }

    if (!this.data.choice_type) {
      this.data.choice_type = SurveyQuestionRestDTOChoiceTypeEnum.Single;
    } else {
      this.selectedChoiceTypeId = this.choiceTypeIndex;
    }
  }

  private get choiceTypeIndex(): number {
    if (this.data.choice_type === SurveyQuestionRestDTOChoiceTypeEnum.Multiple) {
      return 1;
    }

    if (this.data.choice_type === SurveyQuestionRestDTOChoiceTypeEnum.Combobox) {
      return 2;
    }

    return 0;
  }

  private set choiceTypeIndex(index: number) {
    // empty
  }

  private questionTypeSelectionChanged(id: number) {
    const option = this.options.find((o) => o.id === id);
    if (option) {
      this.selectedChoiceTypeId = id;
      switch (option.id) {
        case 0:
          this.data.choice_type = SurveyQuestionRestDTOChoiceTypeEnum.Single;
          break;
        case 1:
          this.data.choice_type = SurveyQuestionRestDTOChoiceTypeEnum.Multiple;
          break;
        default:
          this.data.choice_type = SurveyQuestionRestDTOChoiceTypeEnum.Combobox;
      }
    }
  }

  private get sortedAnswers(): SurveyQuestionAnswerRestDTO[] {
    return (this.data.choice_answers as SurveyQuestionAnswerRestDTO[]).sort((s1, s2) => ((s1.position as number) < (s2.position as number) ? -1 : 1));
  }

  /* private sortSectionsByIndex() {
    (this.data.choice_answers as SurveyQuestionAnswerRestDTO[]).sort((s1, s2) => ((s1.position as number) < (s2.position as number) ? -1 : 1));
  } */

  private newBlankSectionData(index: number): SurveyQuestionAnswerRestDTO {
    const newSection: SurveyQuestionAnswerRestDTO = {
      id: this.idCounter,
      position: index,
      question_id: this.data.id,
      answer_text: '',
    };

    this.idCounter += 1;

    return newSection;
  }

  private addQuestion() {

    let index = 0;

    if (this.data.choice_answers && this.data.choice_answers.length > 0) {
      index = this.data.choice_answers[this.data.choice_answers.length - 1].position as number + 1;
    }

    const newSection = this.newBlankSectionData(index);

    (this.data.choice_answers as SurveyQuestionAnswerRestDTO[]).push(newSection);
    // this.sortSectionsByIndex();
  }

  private moveSectionUp(index: number) {

    if (index > 0) {
      const sectionToMoveUp = (this.data.choice_answers as SurveyQuestionAnswerRestDTO[]).find((s) => s.position === index);
      const sectionToSwapWith = (this.data.choice_answers as SurveyQuestionAnswerRestDTO[]).find((s) => s.position === (index - 1));

      if (sectionToMoveUp && sectionToSwapWith) {
        sectionToMoveUp.position = index - 1;
        sectionToSwapWith.position = index;
        // this.sortSectionsByIndex();
      }
    }
  }

  private moveSectionDown(index: number) {

    if (this.data.choice_answers && index < this.data.choice_answers.length - 1) {
      const sectionToMoveUp = this.data.choice_answers.find((s) => s.position === index);
      const sectionToSwapWith = this.data.choice_answers.find((s) => s.position === (index + 1));

      if (sectionToMoveUp && sectionToSwapWith) {
        sectionToMoveUp.position = index + 1;
        sectionToSwapWith.position = index;
        // this.sortSectionsByIndex();
      }
    }
  }

  private openSectionDeleteDialog(indexOfSectionToDelete: number) {
    this.indexOfSectionToDelete = indexOfSectionToDelete;
    this.showDeleteDialog = true;
  }

  private cancelSectionDeleteDialog() {
    this.showDeleteDialog = false;
    this.indexOfSectionToDelete = undefined;
  }

  private deleteSection() {

    const sections = (this.data.choice_answers as SurveyQuestionAnswerRestDTO[]).filter((s) => s.position !== this.indexOfSectionToDelete);
    sections.forEach((s) => {
      if ((s.position as number) > (this.indexOfSectionToDelete as number)) {
        // eslint-disable-next-line no-param-reassign
        (s.position as number) -= 1;
      }
    });

    this.indexOfSectionToDelete = undefined;
    this.data.choice_answers = sections;
    // this.sortSectionsByIndex();
    this.showDeleteDialog = false;
  }
}
