

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import SurveyNameTableCardEntry from '@/components/table/SurveyNameTableCardEntry.vue';
import RoundedButtonOutLined from '@/components/partials/RoundedButtonOutlined.vue';
import BaseTableCard from '@/components/table/BaseTableCard.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import Header from '@/assets/headers';
import TableCardEntry from '@/components/table/TableCardEntry.vue';

@Component({
  components: {
    SurveyNameTableCardEntry,
    RoundedButtonOutLined,
    BaseTableCard,
    TextHeader,
    TableCardEntry,
  },
})
export default class SurveyTableCard extends Vue {
    private Header = Header;

    @Prop({ required: true })
    private state!: unknown;

    @Prop({ required: true })
    private name!: string;

    @Prop({ required: true })
    private company!: string;

    @Prop({ required: true })
    private startTime!: string;

    @Prop({ required: true })
    private endTime!: string;

    @Prop({ required: true })
    private answerCount!: number;

    @Prop({ required: true })
    private impressionCount!: number;

    @Prop({ default: false })
    private isAppSpecific!: boolean;

    @Prop({ required: true })
    private withResultButton!: boolean;

    @Prop({ required: true })
    private isAdmin!: boolean;

    @Emit()
    private edit() {
      // emits event
    }

    @Emit()
    private results() {
      // emits event
    }

    @Emit()
    private remove() {
      // emits event
    }

}
