export default class SurveyEditorErrorCodes {

  public static readonly TITLE_EMPTY = new SurveyEditorErrorCodes('TITLE_EMPTY');
  public static readonly DESCRIPTION_EMPTY = new SurveyEditorErrorCodes('DESCRIPTION_EMPTY');
  public static readonly QUESTION_EMPTY = new SurveyEditorErrorCodes('QUESTION_EMPTY');
  public static readonly ANSWER_EMPTY = new SurveyEditorErrorCodes('ANSWER_EMPTY');
  public static readonly NO_QUESTIONS_ADDED = new SurveyEditorErrorCodes('NO_QUESTIONS_ADDED');
  public static readonly NO_ANSWERS_ADDED = new SurveyEditorErrorCodes('NO_ANSWERS_ADDED');

  private readonly name: string;

  constructor(name: string) {
    this.name = name;
  }
}
