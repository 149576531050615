
















import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import SurveyEditorErrorCodes from '@/assets/SurveyEditorErrorCodes';
import TextHeader from '@/components/partials/TextHeader.vue';
import Header from '@/assets/headers';

@Component({
  components: { TextHeader },
})
export default class TextInput extends Vue {
  private Header = Header;
  private SurveyEditorErrorCodes = SurveyEditorErrorCodes;

  @Prop({ default: '' })
  private readonly label!: string;

  @Prop({ default: '' })
  private readonly placeholder!: string;

  @VModel({ default: '' })
  private input!: string;

  @Prop({ default: 'text' })
  private readonly type!: string;

  @Prop({ default: false })
  private readonly disabled!: boolean;

  @Prop({ default: () => [] })
  private errors!: string[];

  @Emit()
  private enterPressed() {
    // empty
  }

  @Emit()
  private changed() {
    // empty
  }
}
