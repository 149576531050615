


















































































































































































































































import { AxiosError, AxiosResponse } from 'axios';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { ProjectOverviewRestDto } from '@/apis/projectapi';
import {
  FileUpload,
  RejectionReason,
  SurveyRestDTO,
  SurveySectionRestDTO,
  SurveyStatus,
} from '@/apis/surveyapi';
import ButtonType from '@/assets/buttonTypes';
import Header from '@/assets/headers';
import MessageBoxType from '@/assets/messageBoxTypes';
import Role from '@/assets/roles';
import DateService from '@/assets/services/DateService';
import Services from '@/assets/services/Services';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import AddBlockButton from '@/components/news/AddBlockButton.vue';
import BackLinkWithArrow from '@/components/partials/BackLinkWithArrow.vue';
import DateTimeInput from '@/components/partials/DateTimeInput.vue';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import MessageBox from '@/components/partials/MessageBox.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import SingleImageUpload, { UploadImageData } from '@/components/SingleImageUpload.vue';
import SurveySection from '@/components/surveys/editor/SurveySection.vue';
import SurveyCheckBoxTable from '@/components/surveys/SurveyCheckBoxTable.vue';
import SurveyRejectionCard from '@/components/table/SurveyRejectionCard.vue';
import Table from '@/components/table/Table.vue';
import Page from '@/components/partials/Page.vue';

export interface ValidationError {
  code: 'TITLE_EMPTY' | 'TITLE_ALREADY_EXISTS' | 'DESCRIPTION_EMPTY' | 'DESCRIPTION_TO_LONG' | 'NO_BANNER_IMAGE' | 'NO_SECTIONS' | 'SECTION_EMPTY' | 'SCALA_QUESTION_NO_SUB_QUESTIONS' | 'CHOICE_QUESTION_NO_ANSWERS' | 'QUESTION_EMPTY' | 'FREETEXT_HINT_EMPTY' | 'FREETEXT_QUESTION_EMPTY' | 'SLIDER_QUESTION_EMPTY' | 'SLIDER_START_COLOR_EMPTY' | 'SLIDER_END_COLOR_EMPTY' | 'SLIDER_START_COLOR_INVALID' | 'SLIDER_END_COLOR_INVALID' | 'SUB_QUESTION_EMPTY' | 'CHOICE_QUESTION_EMPTY' | 'START_TIME_AFTER_END_TIME'| 'START_TIME_EMPTY' | 'END_TIME_EMPTY' | 'SUB_QUESTION_TOO_LONG' | 'CHOICE_QUESTION_TOO_LONG' | 'FREETEXT_QUESTION_TOO_LONG' | 'FREETEXT_HINT_TOO_LONG',
  location: 'GLOBAL' | 'SECTION' | 'QUESTION' | 'SUB_QUESTION';
  sectionPosition: number;
  position: number;
  subPosition: number;
}

@Component({
  components: {
    AddBlockButton,
    SurveyRejectionCard,
    Table,
    MessageBox,
    ConfirmDialog,
    DateTimeInput,
    LoadingAnimation,
    RoundedButtonFilled,
    DeleteDialog,
    SurveySection,
    IconInCircle,
    SingleImageUpload,
    SurveyCheckBoxTable,
    TextInputArea,
    TextInput,
    TextHeader,
    BackLinkWithArrow,
    Page,
  },
  metaInfo: {
    title: 'Umfragen │ innovaMo',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class SurveyEditor extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private Role = Role;
  private SurveyStatus = SurveyStatus;
  private MessageBoxType = MessageBoxType;

  private initialLoading = true;
  private showUnexpectedErrorMessage = false;
  private showDeleteDialog = false;
  private indexOfSectionToDelete: number | undefined = undefined;
  private idCounter = 0;
  private errors: ValidationError[] = [];
  private loading = false;
  private showSuccessMessage = false;
  private uploadSuccessful = false;

  private title: string = '';
  private description: string = '';

  private logoImageFile: File | null = null;
  private logoSuccessFullyUploaded: boolean = false;
  private showConfirmReviewDialog: boolean = false;
  private showRejectionDialog: boolean = false;
  private rejectionDialogErrorMessages: string[] = [];
  private rejectionReasonText: string = '';

  private developerProjects: ProjectOverviewRestDto[] = [];

  @Prop({ default: undefined })
  private readonly surveyUUID!: string;

  @Watch('survey.isAppSpecific')
  private onIsAppSpecificChanged(isAppSpecific: boolean) {
    if (!isAppSpecific) {
      this.survey.mobileAppKey = undefined;
    }
  }

  private editExistingSurvey: boolean = false;

  private get startTime(): Date {
    if (this.survey && this.survey.time_start) {
      return new Date(Date.parse(this.survey.time_start as string));
    }
    return new Date();
  }

  private set startTime(date: Date) {
    date.setMilliseconds(0);
    date.setSeconds(0);
    this.survey.time_start = date.toISOString();
  }

  private get endTime(): Date {
    if (this.survey && this.survey.time_end) {
      return new Date(Date.parse(this.survey.time_end as string));
    }
    return new Date();
  }

  private set endTime(date: Date) {
    date.setMilliseconds(0);
    date.setSeconds(0);
    this.survey.time_end = date.toISOString();
  }

  private dateTimeFormatter = DateService.dateTimeFormatter;

  private formatRejectionDate(date: string) {
    return this.dateTimeFormatter.format(new Date(Date.parse(date)));
  }

  private survey: SurveyRestDTO = {
    id: undefined,
    uuid: '',
    title: '',
    description: '',
    developer_id: undefined,
    status: SurveyStatus.Editing,
    isAppSpecific: false,
    mobileAppKey: undefined,
    image: {},
    published: false,
    time_start: undefined,
    time_end: undefined,
    sections: [],
    rejections: [],
  }

  private developerIdToNameMap: Map<string, string> = new Map<string, string>();
  private expandedTableRows: boolean[] = [];

  private get errorsToDisplay() {
    return this.errors.filter((e) => e.location === 'GLOBAL').map((e) => e.code);
  }

  private get logoImagePath(): UploadImageData {

    const imageData: UploadImageData = {
      path: '',
      filename: '',
    };

    if (this.survey.image && this.survey.image.uuid) {

      imageData.filename = `${this.survey.image.filename}.${this.survey.image.filetype}`;

      if (this.survey.image.isTemporaryUpload) {
        imageData.path = `${process.env.VUE_APP_SURVEY_SERVICE_IMAGE_URL}temporary/${this.survey.image.id}`;
      } else {
        imageData.path = `${process.env.VUE_APP_SURVEY_SERVICE_IMAGE_URL}${this.survey.image.id}`;
      }
    }

    return imageData;
  }

  private bannerImage: UploadImageData = {
    path: '',
    filename: '',
  };

  private get currentRejectionReasonText(): string {
    if (this.editExistingSurvey && this.survey && this.survey.rejections && this.survey.rejections.length > 0) {
      return this.rejectionsSortedByDate[0].text as string;
    }

    return '';
  }

  private get rejectionsSortedByDate(): RejectionReason[] {
    if (!this.survey || !this.survey.rejections) return [];

    return this.survey.rejections.sort(
      (a: RejectionReason, b: RejectionReason) => Date.parse(b.date as string) - Date.parse(a.date as string),
    );
  }

  private get sortedSections(): SurveySectionRestDTO[] {
    return (this.survey.sections as SurveySectionRestDTO[]).sort((s1, s2) => ((s1.position as number) < (s2.position as number) ? -1 : 1));
  }

  private async mounted() {

    if (this.surveyUUID) {
      try {
        const surveyResponse = await Services.surveys.getSurveyById(this.surveyUUID);
        this.survey = surveyResponse.data;

        if (this.survey.sections && this.survey.sections.length > 0) {
          // eslint-disable-next-line prefer-spread
          this.idCounter = Math.max.apply(Math, this.survey.sections.map((s) => s.id as number)) + 1;
        }
        this.editExistingSurvey = true;

        if (this.$store.state.role === Role.ADMIN) {
          await this.loadAllDeveloperNames();
        }

        if (this.survey.rejections) {
          for (let i = 0; i < this.survey.rejections?.length; i += 1) {
            this.expandedTableRows.push(false);
          }
        }
      } catch (e) {
        this.showUnexpectedErrorMessage = true;
      }
    } else {
      this.survey.sections = [
        this.newBlankSection(0),
      ];
    }

    if (!this.survey.time_start) {
      this.startTime = new Date();
    }

    if (!this.survey.time_end) {
      const dateTimeNow = new Date();
      dateTimeNow.setDate(dateTimeNow.getDate() + 7);
      this.endTime = dateTimeNow;
    }

    try {
      const developerId = this.editExistingSurvey ? this.survey.developer_id : this.$store.state.userId;
      this.survey.developer_id = developerId;

      const developerAppsResponse = await Services.projects.getProjectsByDeveloperUserId(developerId);
      this.developerProjects = developerAppsResponse.data.filter((project: ProjectOverviewRestDto) => project.surveyKey);
    } catch (e) {
      // this.showUnexpectedErrorMessage = true;
    }

    this.initialLoading = false;
  }

  private async uploadBannerImage(file: File) {
    if (this.survey.image && this.survey.image.id !== undefined) {
      await this.deleteBannerImage();
    }
    const response = await Services.surveys.uploadImageTemporary(file);
    this.survey.image = response.data;
    this.logoSuccessFullyUploaded = true;
  }

  private async deleteBannerImage() {
    this.logoImageFile = null;
    this.logoSuccessFullyUploaded = false;

    if (this.survey.image && this.survey.image.isTemporaryUpload) {
      await Services.surveys.deleteTemporaryImage(this.survey.image.id as number);
    } else {
      await Services.surveys.deleteImage((this.survey.image as FileUpload).id as number);
    }

    this.survey.image = {};
  }

  private newBlankSection(index: number): SurveySectionRestDTO {

    const data: SurveySectionRestDTO = {
      survey_id: this.survey.id,
      section_title: '',
      section_description: '',
      questions: [],
      position: index,
      id: this.idCounter,
    };

    this.idCounter += 1;

    return data;
  }

  private addSectionAbove(index: number) {
    const newSection: SurveySectionRestDTO = this.newBlankSection(index);

    const sectionsBefore = (this.survey.sections as SurveySectionRestDTO[]).filter((s) => (s.position as number) < index);
    const sectionsAfter = (this.survey.sections as SurveySectionRestDTO[]).filter((s) => (s.position as number) >= index);

    // eslint-disable-next-line no-param-reassign
    sectionsAfter.forEach((s) => (s.position as number) += 1);

    this.survey.sections = [...sectionsBefore, newSection, ...sectionsAfter];
    // this.sortSectionsByIndex();
  }

  private addSectionBelow(index: number) {
    const newSection: SurveySectionRestDTO = this.newBlankSection(index + 1);

    const sectionsBefore = (this.survey.sections as SurveySectionRestDTO[]).filter((s) => s.position as number <= index);
    const sectionsAfter = (this.survey.sections as SurveySectionRestDTO[]).filter((s) => s.position as number > index);

    // eslint-disable-next-line no-param-reassign
    sectionsAfter.forEach((s) => (s.position as number) += 1);

    this.survey.sections = [...sectionsBefore, newSection, ...sectionsAfter];
  }

  private moveSectionUp(index: number) {
    if (index > 0) {
      const sectionToMoveUp = (this.survey.sections as SurveySectionRestDTO[]).find((s) => s.position === index);
      const sectionToSwapWith = (this.survey.sections as SurveySectionRestDTO[]).find((s) => s.position === (index - 1));

      if (sectionToMoveUp && sectionToSwapWith) {
        sectionToMoveUp.position = index - 1;
        sectionToSwapWith.position = index;
      }
    }
  }

  private moveSectionDown(index: number) {

    if (index < (this.survey.sections as SurveySectionRestDTO[]).length - 1) {
      const sectionToMoveUp = (this.survey.sections as SurveySectionRestDTO[]).find((s) => s.position === index);
      const sectionToSwapWith = (this.survey.sections as SurveySectionRestDTO[]).find((s) => s.position === (index + 1));

      if (sectionToMoveUp && sectionToSwapWith) {
        sectionToMoveUp.position = index + 1;
        sectionToSwapWith.position = index;
      }
    }
  }

  private openSectionDeleteDialog(indexOfSectionToDelete: number) {
    this.indexOfSectionToDelete = indexOfSectionToDelete;
    this.showDeleteDialog = true;
  }

  private cancelSectionDeleteDialog() {
    this.showDeleteDialog = false;
    this.indexOfSectionToDelete = undefined;
  }

  private deleteSection() {

    const sections: SurveySectionRestDTO[] = (this.survey.sections as SurveySectionRestDTO[]).filter((s) => s.position !== this.indexOfSectionToDelete);
    sections.forEach((s) => {
      if (s.position as number > (this.indexOfSectionToDelete as number)) {
        // eslint-disable-next-line no-param-reassign
        (s.position as number) -= 1;
      }
    });

    this.indexOfSectionToDelete = undefined;
    this.survey.sections = sections;
    this.showDeleteDialog = false;
  }

  private async save(): Promise<SurveyRestDTO> {
    let result: AxiosResponse<SurveyRestDTO>;
    if (this.editExistingSurvey) {
      result = await Services.surveys.updateSurveyById(this.survey.uuid as string, this.survey);
    } else {
      result = await Services.surveys.addSurvey(this.survey);
    }

    return result.data;

  }

  private hideSuccessMessageAfter5Seconds() {
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 5000);
  }

  private async saveSurvey() {
    try {
      this.loading = true;
      this.showSuccessMessage = false;
      this.survey = await this.save();
      this.editExistingSurvey = true;
      this.loading = false;
      this.showSuccessMessage = true;
      this.uploadSuccessful = true;
      this.errors = [];
    } catch (err: unknown) {
      const error = err as AxiosError;

      if (error.response && error.response.data.errors && error.response.data.errors.length > 0) {
        this.errors = error.response.data.errors.map((e: any) => ({
          code: e.code,
          location: e.location,
          sectionPosition: e.sectionPosition,
          position: e.position,
          subPosition: e.subPosition,
        }));
      }

      this.loading = false;
      this.showSuccessMessage = true;
      this.uploadSuccessful = false;
      console.log(error);
    }

    this.hideSuccessMessageAfter5Seconds();
  }

  private async publishSurvey() {

    const publishStateBeforeSaving: SurveyStatus = this.survey.status as SurveyStatus;

    try {
      this.loading = true;
      this.showSuccessMessage = false;

      this.survey.status = SurveyStatus.InReview;
      this.survey = await this.save();
      this.editExistingSurvey = true;
      this.loading = false;
      this.showSuccessMessage = true;
      this.uploadSuccessful = true;
      this.errors = [];
      await Services.mails.sendSurveyToReviewNotificationToAdmins({ surveyName: this.survey.title });
    } catch (err: unknown) {
      const error = err as AxiosError;

      this.survey.status = publishStateBeforeSaving;

      if (error.response && error.response.data.errors && error.response.data.errors.length > 0) {
        this.errors = error.response.data.errors.map((e: any) => ({
          code: e.code,
          location: e.location,
          sectionPosition: e.sectionPosition,
          position: e.position,
          subPosition: e.subPosition,
        }));
      }

      this.loading = false;
      this.showSuccessMessage = true;
      this.uploadSuccessful = false;
    }

    this.hideSuccessMessageAfter5Seconds();
  }

  private openConfirmReviewDialog() {
    this.showConfirmReviewDialog = true;
  }

  private confirmReviewDialog() {
    this.showConfirmReviewDialog = false;
    this.publishSurvey();
  }

  private cancelReviewDialog() {
    this.showConfirmReviewDialog = false;
  }

  private async acceptSurvey() {
    try {
      this.loading = true;
      await Services.surveys.acceptSurvey(this.survey.uuid as string);
      const userResponse = await Services.users.getUserByUserId(this.survey.developer_id as string);
      await Services.mails.sendSurveyAcceptedMail({
        email: userResponse.data.emailAddress,
        message: `Ihre Umfrage mit dem Titel ${this.survey.title} wurde akzeptiert.`,
      });
      this.uploadSuccessful = true;
      this.showSuccessMessage = true;
      this.survey.status = SurveyStatus.WaitingForStart;
      this.hideSuccessMessageAfter5Seconds();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  private rejectSurvey() {
    this.showRejectionDialog = true;
  }

  private async confirmRejectionDialog() {

    if (!this.rejectionReasonText.trim()) {
      if (this.rejectionDialogErrorMessages.length <= 0) {
        this.rejectionDialogErrorMessages.push('Es muss ein Grund für die Ablehnung der Umfrage angegeben werden.');
      }
      return;
    }

    try {
      this.rejectionDialogErrorMessages.length = 0;
      this.showRejectionDialog = false;
      this.loading = true;
      await Services.surveys.rejectSurvey({
        surveyUuid: this.surveyUUID,
        reviewerUuid: this.$store.state.userId,
        text: this.rejectionReasonText,
      });

      const userResponse = await Services.users.getUserByUserId(this.survey.developer_id as string);
      await Services.mails.sendSurveyRejectedMail({
        developerMail: userResponse.data.emailAddress,
        surveyName: this.survey.title,
        rejectionReason: this.rejectionReasonText,
      });
      this.rejectionReasonText = '';
      this.uploadSuccessful = true;
      this.showSuccessMessage = true;
      this.survey.status = SurveyStatus.Rejected;
      this.hideSuccessMessageAfter5Seconds();
    } catch (e) {
      console.error(e);
    }
    this.loading = false;

  }

  private cancelRejectionDialog() {
    this.rejectionDialogErrorMessages.length = 0;
    this.showRejectionDialog = false;
    this.rejectionReasonText = '';
  }

  private async loadAllDeveloperNames() {

    if (!this.survey.rejections) return;

    const promises = [];
    const processedDeveloperIds: string[] = Array.from(this.developerIdToNameMap.keys());

    for (let i = 0; i < this.survey.rejections.length; i += 1) {

      const developerId: string = this.survey.rejections[i].reviewerUuid as string;

      if (developerId && !processedDeveloperIds.includes(developerId)) {
        promises.push(this.loadDeveloperName(developerId));
        processedDeveloperIds.push(developerId);
      }
    }

    await Promise.all(promises);
  }

  private async loadDeveloperName(developerId: string) {
    if (developerId && developerId !== '' && !this.developerIdToNameMap.has(developerId)) {
      const response = await Services.users.getUserByUserId(developerId);
      this.developerIdToNameMap.set(developerId, `${response.data.firstname} ${response.data.lastname}`);
    }
  }

}
