






































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import { ValidationError } from '@/views/surveys/SurveyEditor.vue';
import { SurveyQuestionRestDTO } from '@/apis/surveyapi';
import SurveyEditorErrorCodes from '@/assets/SurveyEditorErrorCodes';
import DeleteDialog from '@/components/DeleteDialog.vue';
import SurveyEditorAnswer, { QuestionInput } from '@/components/surveys/editor/SurveyEditorAnswer.vue';
import TextInput from '@/components/partials/TextInput.vue';
import SurveyEditorSingleChoiceBar, { ChoiceBarOption } from '@/components/surveys/editor/SurveyEditorSingleChoiceBar.vue';
import SurveyCheckBoxTable from '@/components/surveys/SurveyCheckBoxTable.vue';
import Header from '@/assets/headers';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import TextHeader from '@/components/partials/TextHeader.vue';

export interface ScaleQuestionData {
  answers: string[];
}

export interface TextQuestionData {
  question: string;
  isQuestionOptional: boolean;
  errors: SurveyEditorErrorCodes[];
}

@Component({
  components: {
    TextInputArea,
    DeleteDialog,
    SurveyEditorAnswer,
    TextInput,
    SurveyEditorSingleChoiceBar,
    SurveyCheckBoxTable,
    IconInCircle,
    TextHeader,
  },
})
export default class SurveyEditorTextQuestion extends Vue {
  private Header = Header;
  private SurveyEditorErrorCodes = SurveyEditorErrorCodes;

  @VModel()
  private data!: SurveyQuestionRestDTO;

  @Prop({ required: true })
  private heading!: string;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: () => ([]) })
  private errors!: ValidationError[];

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }

  private get errorsToDisplay() {
    return this.errors
      .filter((e) => e.location === 'QUESTION' && e.position === this.data.position)
      .map((e) => e.code);
  }
}
