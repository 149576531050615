
















































































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import NewsBlockSelectionDialog, { SelectableDialogEntry } from '@/components/news/NewsBlockSelectionDialog.vue';
import AddBlockButton from '@/components/news/AddBlockButton.vue';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import { ValidationError } from '@/views/surveys/SurveyEditor.vue';
import {
  SurveyQuestionRestDTO,
  QuestionType,
  SurveySectionRestDTO,
} from '@/apis/surveyapi';
import SurveyEditorErrorCodes from '@/assets/SurveyEditorErrorCodes';
import { ChoiceQuestionData } from '@/components/surveys/editor/SurveyEditorChoiceQuestion.vue';
import { SliderQuestionData } from '@/components/surveys/editor/SurveyEditorSliderQuestion.vue';
import { TextQuestionData } from '@/components/surveys/editor/SurveyEditorTextQuestion.vue';
import ButtonType from '@/assets/buttonTypes';
import SurveyEditorQuestionKindWrapper from '@/components/surveys/editor/SurveyEditorQuestionKindWrapper.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import ScalaQuestion, { ScaleQuestionData } from '@/components/surveys/editor/ScalaQuestion.vue';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';
import Header from '@/assets/headers';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';

// eslint-disable-next-line no-shadow
export enum QuestionKind {
  Scala,
  Choice,
  Freetext,
  Slider
}

interface SortableQuestion {
  id: number;
  index: number;
  kind: QuestionKind,
  data: ScaleQuestionData | ChoiceQuestionData | SliderQuestionData | TextQuestionData;
}

export interface SurveySectionData {
  title: string;
  description: string;
  questions: SortableQuestion[];
  errors: SurveyEditorErrorCodes[];
}

@Component({
  components: {
    NewsBlockSelectionDialog,
    AddBlockButton,
    TextInputArea,
    SurveyEditorQuestionKindWrapper,
    DeleteDialog,
    ScalaQuestion,
    RoundedButtonOutlined,
    TextInput,
    IconInCircle,
    TextHeader,
  },
})
export default class SurveySection extends Vue {

    private Header = Header;
    private ButtonType = ButtonType;
    private QuestionType = QuestionType;
    private SurveyEditorErrorCodes = SurveyEditorErrorCodes;

    private showQuestionSelector: boolean = false;
    private showDeleteDialog = false;
    private indexOfSectionToDelete: number | undefined = undefined;
    private idCounter: number = 2;

    private selectableQuestionTypes: SelectableDialogEntry[] = [
      {
        type: QuestionType.Freetext,
        text: 'Freitextfrage',
        iconComponent: 'icon-text-question',
      },
      {
        type: QuestionType.Slider,
        text: 'Sliderfrage',
        iconComponent: 'icon-slider',
      },
      {
        type: QuestionType.Scale,
        text: 'Skalafrage',
        iconComponent: 'icon-settings-horizontal',
      },
      {
        type: QuestionType.Choice,
        text: 'Auswahlfrage',
        iconComponent: 'icon-text-bullets',
      },
    ];

    @Prop({ default: () => ([]) })
    private errors: ValidationError[] = [];

    @VModel({ required: true })
    private data!: SurveySectionRestDTO;

    @Prop({ required: true })
    private heading!: string;

    @Prop({ required: true })
    private readonly positionIndex!: number;

    @Prop({ default: true })
    private readonly upButtonEnabled!: boolean;

    @Prop({ default: true })
    private readonly deleteButtonEnabled!: boolean;

    @Prop({ default: true })
    private downButtonEnabled!: boolean;

    @Emit()
    private moveUp() {
      // empty, emits event
    }

    @Emit()
    private moveDown() {
      // empty, emits event
    }

    @Emit()
    private deleteClicked() {
      // empty, emits event
    }

    @Emit()
    private addAbove() {
      // empty, emits event
    }

    @Emit()
    private addBelow() {
      // empty, emits event
    }

    private get errorsToDisplay() {
      return this.errors
        .filter((e) => e.location === 'SECTION')
        .map((e) => e.code);
    }

    private get sortedQuestions(): SurveyQuestionRestDTO[] {
      return (this.data.questions as SurveyQuestionRestDTO[]).sort((s1, s2) => ((s1.position as number) < (s2.position as number) ? -1 : 1));
    }

    private mounted() {
      if (this.data.questions && this.data.questions.length > 0) {
        // eslint-disable-next-line prefer-spread
        this.idCounter = Math.max.apply(Math, this.data.questions.map((s) => s.id as number)) + 1;
      }
    }

    private newBlankSectionData(index: number, questionKind: QuestionType): SurveyQuestionRestDTO {
      const newSection: SurveyQuestionRestDTO = {
        id: this.idCounter,
        question_type: questionKind,
        position: index,
        freetext_question: '',
        slider_question: '',
        slider_colors: {
          slider_color_left: {
            hex: '',
          },
          slider_color_right: {
            hex: '',
          },
        },
        scale_num_of_answers: undefined,
        scale_type: undefined,
        scale_subquestions: [],
        choice_type: undefined,
        choice_allow_freetext: false,
        choice_freetext_hint: '',
        choice_answers: [],
        is_optional: false,
        section_id: this.data.id,
      };

      this.idCounter += 1;

      return newSection;
    }

    private newBlankQuestionData(questionKind: QuestionKind): ScaleQuestionData | ChoiceQuestionData | SliderQuestionData | TextQuestionData {

      let data: ScaleQuestionData | ChoiceQuestionData | SliderQuestionData | TextQuestionData = {
        question: '',
        isQuestionOptional: false,
        errors: [],
      };

      if (questionKind === QuestionKind.Freetext) {
        data = {
          question: '',
          isQuestionOptional: false,
          errors: [],
        };
      }

      if (questionKind === QuestionKind.Slider) {
        data = {
          colorData: {
            startColor: {
              hex: '',
            },
            endColor: {
              hex: '',
            },
          },
          question: '',
          errors: [],
        };
      }

      if (questionKind === QuestionKind.Scala) {
        data = {
          isAnswerOptional: false,
          numberOfAnswerPossibilities: 3,
          selectedScaleTypeId: 0,
          questions: [],
          errors: [],
        };
      }

      if (questionKind === QuestionKind.Choice) {
        data = {
          selectedScaleTypeId: 0,
          question: '',
          isAnswerOptional: false,
          allowFreeTextAnswer: false,
          freeTextQuestion: 'Sonstiges',
          answers: [],
          errors: [],
        };
      }

      return data;
    }

    private openQuestionTypeSelectionDialog() {
      this.showQuestionSelector = true;
    }

    private closeQuestionTypeSelectionDialog() {
      this.showQuestionSelector = false;
    }

    private addQuestion(questionKind: QuestionType) {

      let index = 0;

      if (this.data.questions && (this.data.questions as SurveyQuestionRestDTO[]).length > 0) {
        index = this.data.questions[this.data.questions.length - 1].position as number + 1;
      }

      const newSection: SurveyQuestionRestDTO = this.newBlankSectionData(index, questionKind);

      (this.data.questions as SurveyQuestionRestDTO[]).push(newSection);

      this.closeQuestionTypeSelectionDialog();
    }

    private moveSectionUp(index: number) {

      if (index > 0) {
        const sectionToMoveUp = (this.data.questions as SurveyQuestionRestDTO[]).find((s) => s.position === index);
        const sectionToSwapWith = (this.data.questions as SurveyQuestionRestDTO[]).find((s) => s.position === (index - 1));

        if (sectionToMoveUp && sectionToSwapWith) {
          sectionToMoveUp.position = index - 1;
          sectionToSwapWith.position = index;
          // this.sortSectionsByIndex();
        }
      }
    }

    private moveSectionDown(index: number) {

      if (index < (this.data.questions as SurveyQuestionRestDTO[]).length - 1) {
        const sectionToMoveUp = (this.data.questions as SurveyQuestionRestDTO[]).find((s) => s.position === index);
        const sectionToSwapWith = (this.data.questions as SurveyQuestionRestDTO[]).find((s) => s.position === (index + 1));

        if (sectionToMoveUp && sectionToSwapWith) {
          sectionToMoveUp.position = index + 1;
          sectionToSwapWith.position = index;
          // this.sortSectionsByIndex();
        }
      }
    }

    private openSectionDeleteDialog(indexOfSectionToDelete: number) {
      this.indexOfSectionToDelete = indexOfSectionToDelete;
      this.showDeleteDialog = true;
    }

    private cancelSectionDeleteDialog() {
      this.showDeleteDialog = false;
      this.indexOfSectionToDelete = undefined;
    }

    private deleteSection() {

      const sections = (this.data.questions as SurveyQuestionRestDTO[]).filter((s) => s.position !== this.indexOfSectionToDelete);
      sections.forEach((s) => {
        if (s.position as number > (this.indexOfSectionToDelete as number)) {
          // eslint-disable-next-line no-param-reassign
          (s.position as number) -= 1;
        }
      });

      this.indexOfSectionToDelete = undefined;
      this.data.questions = sections;
      // this.sortSectionsByIndex();
      this.showDeleteDialog = false;
    }
}
