

































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import { ValidationError } from '@/views/surveys/SurveyEditor.vue';
import { SliderColorDto, SurveyQuestionRestDTO } from '@/apis/surveyapi';
import SurveyEditorErrorCodes from '@/assets/SurveyEditorErrorCodes';
import SurveyEditorSliderInput, { ColorInputData } from '@/components/surveys/editor/SurveyEditorSliderInput.vue';
import QuestionSlider from '@/components/surveys/QuestionSlider.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import SurveyEditorAnswer, { QuestionInput } from '@/components/surveys/editor/SurveyEditorAnswer.vue';
import TextInput from '@/components/partials/TextInput.vue';
import SurveyEditorSingleChoiceBar, { ChoiceBarOption } from '@/components/surveys/editor/SurveyEditorSingleChoiceBar.vue';
import SurveyCheckBoxTable from '@/components/surveys/SurveyCheckBoxTable.vue';
import Header from '@/assets/headers';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import TextHeader from '@/components/partials/TextHeader.vue';

export interface ScaleQuestionData {
  answers: string[];
}

interface SortableSubQuestion {
  id: number;
  index: number;
  data: QuestionInput;
}

export interface SliderQuestionData {
  question: string;
  colorData: ColorInputData;
  errors: SurveyEditorErrorCodes[];
}

@Component({
  components: {
    TextInputArea,
    SurveyEditorSliderInput,
    QuestionSlider,
    DeleteDialog,
    SurveyEditorAnswer,
    TextInput,
    SurveyEditorSingleChoiceBar,
    SurveyCheckBoxTable,
    IconInCircle,
    TextHeader,
  },
})
export default class SurveyEditorSliderQuestion extends Vue {
  private Header = Header;

  @VModel({ required: true })
  private data!: SurveyQuestionRestDTO;

  @Prop({ required: true })
  private heading!: string;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: () => ([]) })
  private errors!: ValidationError[];

  private defaultColorData: ColorInputData = {
    startColor: {
      hex: '#FF5A1C',
    },
    endColor: {
      hex: '#00B38F',
    },
  }

  private get errorsToDisplay() {
    return this.errors
      .filter((e) => e.location === 'QUESTION' && e.position === this.data.position)
      .map((e) => e.code);
  }

  private mounted() {
    if (this.data.slider_colors && this.data.slider_colors.slider_color_left && this.data.slider_colors.slider_color_left.hex === '') {
      this.data.slider_colors.slider_color_left = { hex: this.defaultColorData.startColor.hex } as SliderColorDto;
    }

    if (this.data.slider_colors && this.data.slider_colors.slider_color_right && this.data.slider_colors.slider_color_right.hex === '') {
      this.data.slider_colors.slider_color_right = { hex: this.defaultColorData.endColor.hex } as SliderColorDto;
    }
  }

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }
}
