













































































































































import { Component, Vue } from 'vue-property-decorator';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import { SurveyOverviewRestDTO, SurveyStatus } from '@/apis/surveyapi';
import Header from '@/assets/headers';
import Role from '@/assets/roles';
import Services from '@/assets/services/Services';
import Sort from '@/assets/types/sortingsurveys';
import { Filter } from '@/assets/types/types';
import DeleteDialog from '@/components/DeleteDialog.vue';
import FilterButtonBar from '@/components/partials/FilterButtonBar.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import SearchBar from '@/components/partials/SearchBar.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import SurveyTableCard from '@/components/table/SurveyTableCard.vue';
import Table from '@/components/table/Table.vue';
import ButtonType from '@/assets/buttonTypes';
import DateService from '@/assets/services/DateService';
import Page from '@/components/partials/Page.vue';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';

@Component({
  components: {
    CustomSelect,
    DeleteDialog,
    RoundedButtonFilled,
    TextHeader,
    SearchBar,
    FilterButtonBar,
    SurveyTableCard,
    Table,
    Page,
    UnexpectedErrorMessage,
  },
  metaInfo: {
    title: 'Umfragen │ innovaMo',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class SurveyManagement extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private SortBy = Sort;
  private SurveyStatus = SurveyStatus;
  private isLoading: boolean = true;
  private showUnexpectedErrorMessage: boolean = false;
  private tableHeaders = ['Status', 'Name', 'Unternehmen', 'Startzeit', 'Endzeit', 'a', 'b', 'Aktionen'];
  private noItemsFoundMessage: string = 'Sie haben bisher noch keine Umfragen erstellt oder ihre Suche ergab keine Treffer.';

  private role: Role = Role.DEVELOPER;
  private userId!: string;

  private showDeleteDialog = false;
  private deleteDialogMessage: string = '';
  private surveyToDeleteUuid: string | undefined = undefined;

  private getDeleteMessage(surveyName: string): string {
    return `Soll die Umfrage "${surveyName}" wirklich gelöscht werden?`;
  }

  private filterValue: Filter = undefined;
  private get filterValueGetter() {
    return this.filterValue;
  }

  private sortValue: Option = {
    name: 'Startdatum (ab)',
    value: Sort.START_DATE_DESCENDING,
  };

  private sortOptions: Option[] = [
    {
      name: 'Startdatum (ab)',
      value: Sort.START_DATE_DESCENDING,
    },
    {
      name: 'Startdatum (auf)',
      value: Sort.START_DATE_ASCENDING,
    },
    {
      name: 'Enddatum (ab)',
      value: Sort.END_DATE_DESCENDING,
    },
    {
      name: 'Enddatum (auf)',
      value: Sort.END_DATE_ASCENDING,
    },
    {
      name: 'Name (ab)',
      value: Sort.NAME_DESCENDING,
    },
    {
      name: 'Name (auf)',
      value: Sort.NAME_ASCENDING,
    },
  ];

  private searchTerm: string = '';

  private showFilterDropdown: boolean = false;
  private selectedStateFilters: Array<'Editing' | 'WaitingForStart' | 'InProgress' | 'Done'> = [];

  private currentPage: number = 0;
  private totalPages: number = 0;
  private itemsPerPage: number = 10;
  private totalNumberOfItems: number = 0;

  private isAdmin: boolean = true;

  private appTypeIcons = {
    ios: require('@/assets/images/icons/os-system-apple.svg'),
    android: require('@/assets/images/icons/android-1.svg'),
    web: require('@/assets/images/icons/network-navigation.svg'),
    project: require('@/assets/images/icons/checklist.svg'),
  };

  private editIcons = {
    edit: require('@/assets/images/icons/pencil-1.svg'),
    delete: require('@/assets/images/icons/bin.svg'),
  };

  private publishStates = {
    Editing: {
      name: 'Editing',
      text: 'In Bearbeitung',
      icon: 'icon-edit',
      cssClasses: 'w-6 h-6 p-1 bg-primary-blue text-white rounded-full',
      apiRequestValue: SurveyStatus.Editing,
    },
    InReview: {
      name: 'InReview',
      text: 'In Prüfung',
      icon: 'icon-question-circle',
      cssClasses: 'w-6 h-6 p-1 bg-primary-blue text-white rounded-full',
      apiRequestValue: SurveyStatus.InReview,
    },
    Rejected: {
      name: 'Rejected',
      text: 'Abgelehnt',
      icon: 'icon-alert-circle',
      cssClasses: 'w-6 h-6 p-1 bg-primary-red text-white rounded-full',
      apiRequestValue: SurveyStatus.Rejected,
    },
    WaitingForStart: {
      name: 'WaitingForStart',
      text: 'Wartet auf Start',
      icon: 'icon-alert-circle',
      cssClasses: 'w-6 h-6 p-1 bg-primary-blue text-white rounded-full',
      apiRequestValue: SurveyStatus.WaitingForStart,
    },
    InProgress: {
      name: 'InProgress',
      text: 'Laufend',
      icon: 'icon-question-circle',
      cssClasses: 'w-6 h-6 p-1 bg-green-500 text-white rounded-full',
      apiRequestValue: SurveyStatus.InProgress,
    },
    Done: {
      name: 'Done',
      text: 'Abgeschlossen',
      icon: 'icon-check-circle',
      cssClasses: 'w-7 h-7 text-green-500 rounded-full -ml-0.5',
      apiRequestValue: SurveyStatus.Done,
    },
  };

  private surveys: SurveyOverviewRestDTO[] = [];
  private developerIdToCompanyNameMap: Map<string, string> = new Map<string, string>();

  private dateTimeFormatter = DateService.dateTimeFormatter;

  private get rows() {
    return this.surveys.map((survey: SurveyOverviewRestDTO) => ({
      state: this.mapResponsePublishStateEnumToPublishState(survey.status as SurveyStatus),
      surveyId: survey.id,
      surveyUuid: survey.uuid,
      developerId: survey.developer_id,
      isAppSpecific: survey.is_app_specific,
      name: survey.title,
      startTime: survey.time_start ? `${this.dateTimeFormatter.format(new Date(Date.parse(survey.time_start as string)))} Uhr` : '-',
      endTime: survey.time_end ? `${this.dateTimeFormatter.format(new Date(Date.parse(survey.time_end as string)))} Uhr` : '-',
      impressionCount: survey.nr_of_impressions,
      answerCount: survey.nr_of_answers,
    }));
  }

  private async getCompanyName(developerId: string) {
    let companyName: string = '';
    try {
      if (this.isAdmin) {
        const companyInformationResponse = await Services.users.getUserCompanyInformationByUserId(developerId);
        companyName = companyInformationResponse.data.companyName as string;
      }
    } catch (e) {
      companyName = '-';
    }
    return companyName;
  }

  private get publishStateFiltersForRequest(): SurveyStatus[] {
    return this.selectedStateFilters.map((s) => this.publishStates[s].apiRequestValue);
  }

  private mapResponsePublishStateEnumToPublishState(state: SurveyStatus) {
    switch (state) {
      case SurveyStatus.InReview:
        return this.publishStates.InReview;
      case SurveyStatus.Rejected:
        return this.publishStates.Rejected;
      case SurveyStatus.WaitingForStart:
        return this.publishStates.WaitingForStart;
      case SurveyStatus.InProgress:
        return this.publishStates.InProgress;
      case SurveyStatus.Done:
        return this.publishStates.Done;
      default:
        return this.publishStates.Editing;
    }
  }

  private get currentItemStart(): number {
    return this.currentPage * this.itemsPerPage + 1;
  }

  private get currentItemEnd(): number {
    return this.currentItemStart + this.surveys.length - 1;
  }

  private async mounted(): Promise<void> {

    try {
      const role: Role = this.mapRoleStringToEnumValue(this.$store.state.role);
      this.role = role;
      this.isAdmin = role === Role.ADMIN;

      if (this.role === Role.DEVELOPER) {
        this.userId = this.$store.state.userId;
      }

      this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);

      if (this.isAdmin && this.surveys.length > 0) {
        await this.loadAllCompanyNames();
      }
    } catch (e) {
      this.surveys = [];
      this.showUnexpectedErrorMessage = true;
    } finally {
      this.isLoading = false;
    }
  }

  private async loadAllCompanyNames() {

    const promises = [];
    const processedDeveloperIds: string[] = Array.from(this.developerIdToCompanyNameMap.keys());

    for (let i = 0; i < this.surveys.length; i += 1) {

      const developerId: string = this.surveys[i].developer_id as string;

      if (developerId && !processedDeveloperIds.includes(developerId)) {
        promises.push(this.loadCompanyName(developerId));
        processedDeveloperIds.push(developerId);
      }
    }

    await Promise.all(promises);
  }

  private async loadCompanyName(developerId: string) {
    if (developerId && developerId !== '' && !this.developerIdToCompanyNameMap.has(developerId)) {
      const companyName: string = await this.getCompanyName(developerId);
      this.developerIdToCompanyNameMap.set(developerId as string, companyName);
    }
  }

  private mapRoleStringToEnumValue(role: string): Role {
    switch (role) {
      case 'User':
        return Role.USER;
      case 'Developer':
        return Role.DEVELOPER;
      case 'Admin':
        return Role.ADMIN;
      default:
        return Role.NOROLE;
    }
  }

  private showSurveyResults(id: string) {
    this.$router.push({ name: 'SurveyEvaluation', params: { surveyUUID: id } });
  }

  private editSurvey(id: string) {
    this.$router.push({
      name: 'SurveyEditor',
      params: {
        surveyUUID: id,
      },
    });
  }

  private deleteSurvey(id: string, name: string) {
    this.deleteDialogMessage = this.getDeleteMessage(name);
    this.showDeleteDialog = true;
    this.surveyToDeleteUuid = id;
  }

  private async deleteSurveyConfirmed() {
    this.showDeleteDialog = false;

    if (this.surveyToDeleteUuid) {
      const result = await Services.surveys.deleteSurveyById(this.surveyToDeleteUuid);

      if (result.status === 204) {
        this.surveys = this.surveys.filter((p) => p.id !== this.surveyToDeleteUuid);
      }

      this.surveyToDeleteUuid = undefined;
      this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
    }
  }

  private deleteSurveyCanceled() {
    this.showDeleteDialog = false;
    this.surveyToDeleteUuid = undefined;
  }

  private async searchSurveys(pageNumber: number = 0, searchTerm: string, sortBy: Option): Promise<SurveyOverviewRestDTO[]> {
    try {
      const response = await Services.surveys.getSurveyOverviewsForDeveloper(pageNumber, this.itemsPerPage, searchTerm, this.publishStateFiltersForRequest, sortBy.value.value, sortBy.value.order, this.publishStateFiltersForRequest);
      await this.loadAllCompanyNames();
      this.currentPage = response.data.currentPage as number;
      this.totalNumberOfItems = response.data.totalItemsFound as number;
      this.totalPages = response.data.totalPages as number;

      try {
        if (searchTerm.trim()) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$matomo.trackSiteSearch(searchTerm, 'survey table', response.data.totalItemsFound);
        }
      } catch (e) {
        // do nothing
      }

      return response.data.surveys as SurveyOverviewRestDTO[];
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
      return [];
    }
  }

  private async publishStateSelectionChanged(selectedStateFilters: ('Editing'|'WaitingForStart'|'InProgress'|'Done')[]) {
    this.selectedStateFilters = selectedStateFilters;
    this.currentPage = 0;
    this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
  }

  private async sortValueChanged() {
    this.currentPage = 0;
    this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
  }

  private async filterValueChanged(filterValue: Filter) {
    this.currentPage = 0;
    this.filterValue = filterValue;
    this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
  }

  private async searchButtonClicked(searchTerm: string) {
    this.currentPage = 0;
    this.searchTerm = searchTerm;
    this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
  }

  private async itemsPerPageChanged(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
  }

  private async toFirstPage() {
    if (this.currentPage > 0) {
      this.currentPage = 0;
      this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
    }
  }

  private async previousPage() {
    if (this.currentPage > 0) {
      this.currentPage -= 1;
      this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
    }
  }

  private async nextPage() {
    if (this.currentItemEnd < this.totalNumberOfItems) {
      this.currentPage += 1;
      this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
    }
  }

  private async toLastPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage = this.totalPages - 1;
      this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
    }
  }
}
