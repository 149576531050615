












































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { QuestionType } from '@/apis/surveyapi';
import { ValidationError } from '@/views/surveys/SurveyEditor.vue';
import SurveyEditorSliderQuestion, { SliderQuestionData } from '@/components/surveys/editor/SurveyEditorSliderQuestion.vue';
import SurveyEditorTextQuestion, { TextQuestionData } from '@/components/surveys/editor/SurveyEditorTextQuestion.vue';
import SurveyEditorChoiceQuestion, { ChoiceQuestionData } from '@/components/surveys/editor/SurveyEditorChoiceQuestion.vue';
import ScalaQuestion, { ScaleQuestionData } from '@/components/surveys/editor/ScalaQuestion.vue';

@Component({
  components: {
    SurveyEditorSliderQuestion, SurveyEditorTextQuestion, SurveyEditorChoiceQuestion, ScalaQuestion,
  },
})
export default class SurveyEditorQuestionKindWrapper extends Vue {

  private QuestionKind = QuestionType;

  @VModel()
  private data!: ScaleQuestionData | ChoiceQuestionData | SliderQuestionData | TextQuestionData;

  @Prop({ required: true })
  private readonly questionType!: QuestionType;

  @Prop({ required: true })
  private heading!: string;

  @Prop({ default: true })
  private readonly upButtonEnabled!: boolean;

  @Prop({ default: true })
  private downButtonEnabled!: boolean;

  @Prop({ default: () => ([]) })
  private errors!: ValidationError[];

  @Emit()
  private moveUp() {
    // empty, emits event
  }

  @Emit()
  private moveDown() {
    // empty, emits event
  }

  @Emit()
  private deleteClicked() {
    // empty, emits event
  }

  @Emit()
  private addAbove() {
    // empty, emits event
  }

  @Emit()
  private addBelow() {
    // empty, emits event
  }
}
