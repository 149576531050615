










import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';

@Component({
  components: {
    TextHeader,
  },
})
export default class TableCardEntry extends Vue {
  private Header = Header;

  @Prop({ default: false })
  private isAppSpecific!: boolean;

  @Prop({ default: 'Header' })
  private header!: string;

  @Prop({ default: 'Text' })
  private text!: string;
}
