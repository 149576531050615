export default class Sort {
  public static readonly NAME_ASCENDING = new Sort('name', 'asc');
  public static readonly NAME_DESCENDING = new Sort('name', 'desc');
  public static readonly START_DATE_ASCENDING = new Sort('start-date', 'asc');
  public static readonly START_DATE_DESCENDING = new Sort('start-date', 'desc');
  public static readonly END_DATE_ASCENDING = new Sort('end-date', 'asc');
  public static readonly END_DATE_DESCENDING = new Sort('end-date', 'desc');

  public readonly value: 'name' | 'start-date' | 'end-date';
  public readonly order: 'asc' | 'desc';

  private constructor(value: 'name' | 'start-date' | 'end-date', order: 'asc' | 'desc') {
    this.value = value;
    this.order = order;
  }
}
