






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IconInCircle extends Vue {
  @Prop({ required: true })
  private readonly src!: string;

  @Prop({ default: 'accent-blue' })
  private readonly color!: string;

  @Prop({ default: 'white' })
  private readonly backgroundColor!: string;

  @Prop({ default: 12 })
  private readonly size!: number;

  private get classes(): string {
    return `inline w-${this.size} h-${this.size} px-0 py-0 mx-0 rounded-full border border-solid border-${this.color}
            bg-${this.backgroundColor} text-${this.color} inline-flex items-center justify-center`;
  }

  private get iconClasses() {
    return `w-${this.size / 2} h-${this.size / 2} bg-black bg-${this.color}`;
  }

  private get styles(): string {
    return `mask-image: url(${this.src});
            -webkit-mask-image: url(${this.src});`;
  }
}
